export function buildBoardPath(rowId: string, slug: string, path: string | null | undefined) {
	if (slug === '/') {
		return slug;
	}

	if (path) {
		return `${path},${rowId}`;
	}

	return `${slug},${rowId}`;
}
