import { ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef, useId } from 'react';

import { IconSystem } from '@/icons/IconSystem';
import { IconVariantMap } from '@/icons/icons-system';

import { IconButtonProps } from './IconButton';
import styles from './IconSystemButton.module.scss';

type Props<IconName extends keyof IconVariantMap = keyof IconVariantMap> = IconButtonProps<IconName> & HTMLAttributes<HTMLButtonElement>;

function IconSystemButtonInner<IconName extends keyof IconVariantMap>(
	{ type, icon, variant, className = '', children, size = 'm', ...buttonProperties }: PropsWithChildren<Props<IconName>>,
	ref: ForwardedRef<HTMLButtonElement>
) {
	const id = `isb-${useId()}`;

	return (
		<button
			// this is necessary to prevent hydration matches for react-tiny-popover wrapped components
			suppressHydrationWarning={true}
			ref={ref}
			className={`${styles.button} ${styles[type]} ${styles[size]} ${className} no-focus no-hover`}
			type="button"
			id={id}
			{...buttonProperties}
		>
			<IconSystem icon={icon} variant={variant} size="m" className={styles.icon} aria-labelledby={id} />
			{children}
		</button>
	);
}

export const IconSystemButton = forwardRef(IconSystemButtonInner);
